.title[data-v-fa07df48] {
  height: 75px;
  line-height: 75px;
  margin-bottom: 0;
  margin-right: 8px;
}
.table-container[data-v-fa07df48] {
  height: 100%;
  overflow: hidden;
}
