.tool_container[data-v-b17e1350] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.tool_container .pm_toolbar[data-v-b17e1350] {
  display: flex;
  flex-direction: row;
}
.tool_container .pm_toolbar .input-container[data-v-b17e1350] {
  white-space: nowrap;
  margin-right: 10px;
}
.tool_container .completed_qty_btn .boq_button_group[data-v-b17e1350] {
  margin-right: 20px;
}
@media (min-width: 640px) {
.input-container[data-v-b17e1350] {
    display: flex;
    margin-right: 8px;
}
.completed_qty_btn[data-v-b17e1350] {
    float: right;
}
}
@media (max-width: 640px) {
.input-container[data-v-b17e1350] {
    width: 100%;
}
.completed_qty_btn[data-v-b17e1350] {
    float: none;
    padding-top: 12px;
}
}
.projectLink .el-select-dropdown__item[data-v-b17e1350] {
  height: 59px !important;
}
.projectLink .el-select-dropdown__item > p[data-v-b17e1350] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-b17e1350] {
  font-size: 12px;
  line-height: 1em;
}
